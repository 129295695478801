.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #4177ff;
  color: #f0c600;
}

.merriweather-light {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-bold {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
}

.merriweather-black {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: normal;
}

.merriweather-light-italic {
  font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: italic;
}

.merriweather-regular-italic {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: italic;
}

.merriweather-bold-italic {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: italic;
}

.merriweather-black-italic {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-style: italic;
}

.merriweather-sans-<uniquifier> {
  font-family: "Merriweather Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

.yeseva-one-regular {
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-style: normal;
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-header h1 {
  font-size: 5rem;
  margin: 0.5rem 0;
  font-family: 'Yeseva One', Georgia, serif;
}

.App-header p {
  font-size: 2rem;
  margin: 0.5rem 0;
  font-family: 'Merriweather', Helvetica, sans-serif;
}

.App-logo {
    width: 300px; /* Adjust the size as needed */
    margin-bottom: -5vh;
}

.social-links {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.social-links a {
  color:  #f2d652;
  text-decoration: none;
  margin: 0.5rem 1rem;
  font-size: 3rem;
}

.social-links a:hover {
  text-decoration: none;
  color: #677986;
}
